<template>
  <Modal v-if="modalOpened" :modalOpened="modalOpened" @close="modalOpened = false">
    <div class="relative lg:m-18 md:m-6 m-0 flex">
      <div
        class="close-modal-btn flex justify-center items-center cursor-pointer"
        @click="modalOpened = false"
      >
        <i class="fas fa-times"></i>
      </div>
      <div class="selling-point-modal grid grid-cols-2 gap-x-10">
        <div class="col-span-2 md:col-span-1 flex flex-col justify-between">
          <div>
            <div class="w-full flex justify-center">
              <div
                class="site-logo mt-8"
                :style="`background-image: url(${shop.image});`"
              ></div>
            </div>
            <div class="lora-bold my-6">{{ shop.title }}</div>
            <div class="description" v-html="shop.description"></div>
          </div>
          <div @click="openMap()" class="map-button mt-8" style="background: url(/static/images/map.png)">
            <div
              class="button-content flex items-center justify-center lora-bold text-bluePrimary relative"
            >
              {{ content.locationOnMap }}
              <i class="fas fa-chevron-right map-indicator"></i>
            </div>
          </div>
        </div>
        <div
          class="col-span-2 relative md:col-span-1 mt-5 lg:mt-0"
          v-if="images && images.length > 0"
        >
          <swiper
            :slides-per-view="1"
            :breakpoints="breakpoints"
            :space-between="0"
            :navigation="navigation"
            :loop="true"
          >
            <swiper-slide v-for="(image, index) in images" :key="index" class="w-full">
              <div
                class="online-sale-point-slide-img bg-contain"
                :style="`background-image: url(${image})`"
              ></div>
            </swiper-slide>
          </swiper>
          <div class="next-img-button" id="nextImage">
            <i class="fas fa-arrow-right text-bluePrimary"></i>
          </div>
          <div class="prev-img-button" id="prevImage">
            <i class="fas fa-arrow-right text-bluePrimary"></i>
          </div>
        </div>
      </div>
    </div>
  </Modal>

  <div class="selling-card-container">
    <div class="img-container">
      <div class="card-img" :style="`background: url(${shop.image})`"></div>
    </div>
    <div class="selling-card-content p-6">
      <div class="card-title lora-bold line-clamp-1">{{ shop.title }}</div>
      <div class="description mt-3 line-clamp-3" v-html="shop.description"></div>
      <div
        class="flex items-center justify-center mt-3 view-shop-btn"
        @click="modalOpened = !modalOpened"
      >
        <div class="lora-bold">
          {{ content.viewShop }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/navigation/navigation.min.css";
SwiperCore.use([Navigation, Pagination]);

export default {
  components: { Swiper, SwiperSlide },
  props: ["shop"],
  inject: ["content"],
  data() {
    return {
      modalOpened: false,
      navigation: {
        nextEl: "#nextImage",
        prevEl: "#prevImage",
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
      },
    };
  },
  computed: {
    images() {
      let images = [];

      if (this.shop?.image) images.push(this.shop?.image);

      if (this.shop?.images) {
        for (let i = 0; i < this.shop?.images?.length; i++) {
          images.push(this.shop?.images[i].url);
        }
      }

      return images;
    },
  },
  methods: {
    openMap() {
      window.open(
        `https://maps.google.com/?q=${this.shop.latitude},${this.shop.longitude}`,
        "_blank"
      );
    },
  },
};
</script>
