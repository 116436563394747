<template>
  <div class="online-selling-container">
    <div class="title">{{ content.sellingSites }}</div>
    <div class="grid grid-cols-6 gap-y-10 gap-x-0 md:gap-x-10 mt-6" v-if="sellingPoints">
      <div
        class="col-span-6 md:col-span-3 lg:col-span-2"
        v-for="shop in sellingPoints"
        :key="shop.id"
      >
        <SellingPointsCard :shop="shop" />
      </div>
    </div>
    <div class="my-8 relative"></div>
  </div>
</template>

<script>
import SellingPointsCard from "/src/components/ui/SellingPoints/SellingPointsCard.vue";

export default {
  components: {
    SellingPointsCard,
  },
  inject: ["content"],
  computed:{
    sellingPoints(){
      return this.$store.getters['shops/sellingPoints']
    }
  },
};
</script>